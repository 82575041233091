$(document).ready(function () {
    $('#last-carusel').owlCarousel({
        items: 1,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [980, 1],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        autoPlay: 3000,
        navigation: false,
        navigationText: ['<i class="slider-left"></i>', '<i class="slider-right"></i>'],
        pagination: true
    });
    $('#last-carusel-1').owlCarousel({
        items: 1,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [980, 1],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        autoPlay: 3000,
        navigation: false,
        navigationText: ['<i class="slider-left"></i>', '<i class="slider-right"></i>'],
        pagination: true
    });
    $('#last-carusel-2').owlCarousel({
        items: 1,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [980, 1],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        autoPlay: 3000,
        navigation: false,
        navigationText: ['<i class="slider-left"></i>', '<i class="slider-right"></i>'],
        pagination: true
    });
    $('#logo-home').owlCarousel({
        items: 7,
        itemsDesktop: [1199, 5],
        itemsDesktopSmall: [980, 4],
        itemsTablet: [768, 3],
        itemsMobile: [479, 1],
        autoPlay: 6000,
        autoHeight:false,
        navigation: false,
        navigationText: ['<i class="slider-left"></i>', '<i class="slider-right"></i>'],
        pagination: true
    });

    $('#project-carousel').owlCarousel({
        items: 3,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [980, 3],
        itemsTablet: [768, 2],
        itemsMobile: [479, 1],
        autoPlay: 3000,
        autoHeight:false,
        navigation: true,
        navigationText: ['<div></div>', '<div></div>'],
        pagination: false
    });





    // FIXED MENU
    /*var $menu = $("header");

    $(window).scroll(function () {
        hHeader = ($('header').height());
        if ($(this).scrollTop() > hHeader ) {
            $('header + div').attr("style", "margin-top: 155px");
            $menu.addClass("fixed");

        } else if ($(this).scrollTop() <= hHeader) {
            $menu.removeClass("fixed");
            $('header + div').attr("style", "");
        }
    });*/

    // Mobile menu

    $("#menu-btn").click(function() {
        $("#nav-top").toggleClass("active");
        $(".fa.fa-bars").toggleClass("fa-close");
    });

    // Menu active
    $('.nav a').each(function () {
        var location = window.location.href;
        var link = this.href;
        if (location == link) {
            $(this).addClass('menu_active');
        }
    });
    // Scroll to top
    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            $('#scroller').fadeIn();
        } else {$('#scroller').fadeOut();}
    });
    $('#scroller').click(function () {
        $('body,html').animate({scrollTop: 0}, 400);
        return false;
    });

    // Button .Add Animation
    $(window).resize(function () {
        var curW = $(this).width();
        if (curW > (768 - 17)) {
            $('.hex-add').attr("style", "transition: 0s")

        }
    });

});
